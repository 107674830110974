import * as Types from '../../../graphql';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetEFacturaClientConfigQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
}>;


export type GetEFacturaClientConfigQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaClientConfigUsingGET?: { __typename?: 'accounts_EFacturaClientConfigRepresentation', lastRefreshMoment?: string | null, automaticInvoiceSubmitAfterNumDays?: number | null, isAutomaticInvoiceSubmitEnabled?: boolean | null } | null } | null };

export type UpdateEFacturaClientConfigAutomaticSubmissionMutationVariables = Types.Exact<{
  clientEid?: Types.InputMaybe<Types.Scalars['String']>;
  request?: Types.InputMaybe<Types.Accounts_EFacturaClientConfigAutomaticSubmissionUpdateRepresentationInput>;
}>;


export type UpdateEFacturaClientConfigAutomaticSubmissionMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', updateEFacturaClientConfigAutomaticSubmissionUsingPUT?: { __typename?: 'accounts_EFacturaClientConfigRepresentation', lastRefreshMoment?: string | null, automaticInvoiceSubmitAfterNumDays?: number | null, isAutomaticInvoiceSubmitEnabled?: boolean | null } | null } | null };

export type UpdateEFacturaListaMesajeMutationVariables = Types.Exact<{
  zile: Types.Scalars['Int'];
  clientEid: Types.Scalars['String'];
}>;


export type UpdateEFacturaListaMesajeMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', updateEFacturaListaMesajeUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type GetEFacturaStatisticsQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
}>;


export type GetEFacturaStatisticsQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaStatisticsUsingGET?: { __typename?: 'accounts_EFacturaStatisticsRepresentation', outboxWithErrorCount?: number | null, inboxNotVerifiedCount?: number | null } | null } | null };

export type GetEFacturaOutboxOpsQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  logicalGroup?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetEFacturaOutboxOpsQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getCompanyParamListUsingGET?: { __typename?: 'accounts_CompanyParamListRepresentation', data?: Array<{ __typename?: 'accounts_CompanyParamRepresentation', logicalGroup?: string | null, code?: string | null, value?: string | null }> | null } | null } | null };

export type GetEFacturaInboxListQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetEFacturaInboxListQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaInboxListUsingGET?: { __typename?: 'accounts_EFacturaInboxListRepresentation', recordsCount?: number | null, first?: number | null, last?: number | null, pageSize?: number | null, data?: Array<{ __typename?: 'accounts_EFacturaInboxRepresentation', externalId?: string | null, clientEid?: string | null, clientName?: string | null, status?: string | null, documentType?: string | null, issueDate?: number | null, amount?: number | null, momentCreated?: string | null, serialNo?: string | null, partnerCif?: string | null, partnerName?: string | null, originalFileEid?: string | null, readableFileEid?: string | null, documentEid?: string | null, lastStatusChangeDateTime?: string | null, lastStatusChangeUserEid?: string | null, lastStatusChangeUserName?: string | null, dueDate?: number | null, timeUploaded?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, isProtected?: boolean | null, messageOutbox?: Array<{ __typename?: 'accounts_EFacturaOutboxNotificationMessageRepresentation', clientEid?: string | null, clientName?: string | null, externalId?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, message?: string | null, momentCreated?: string | null, momentUploaded?: string | null, originalFileEid?: string | null, partnerCif?: string | null, partnerName?: string | null, userEid?: string | null, userName?: string | null }> | null }> | null } | null } | null };

export type GetEFacturaInboxQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  externalId: Types.Scalars['String'];
}>;


export type GetEFacturaInboxQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaInboxUsingGET?: { __typename?: 'accounts_EFacturaInboxRepresentation', externalId?: string | null, clientEid?: string | null, clientName?: string | null, status?: string | null, documentType?: string | null, issueDate?: number | null, amount?: number | null, momentCreated?: string | null, serialNo?: string | null, partnerCif?: string | null, partnerName?: string | null, originalFileEid?: string | null, readableFileEid?: string | null, documentEid?: string | null, lastStatusChangeDateTime?: string | null, lastStatusChangeUserEid?: string | null, lastStatusChangeUserName?: string | null, dueDate?: number | null, timeUploaded?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, isProtected?: boolean | null, messageOutbox?: Array<{ __typename?: 'accounts_EFacturaOutboxNotificationMessageRepresentation', clientEid?: string | null, clientName?: string | null, externalId?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, message?: string | null, momentCreated?: string | null, momentUploaded?: string | null, originalFileEid?: string | null, partnerCif?: string | null, partnerName?: string | null, userEid?: string | null, userName?: string | null }> | null } | null } | null };

export type GetEFacturaInboxHistoryQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  externalId: Types.Scalars['String'];
}>;


export type GetEFacturaInboxHistoryQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaInboxHistoryUsingGET?: { __typename?: 'accounts_EFacturaInboxHistoryListRepresentation', data?: Array<{ __typename?: 'accounts_EFacturaInboxHistoryRepresentation', externalId?: string | null, description?: string | null, performedDateTime?: string | null, performedUserEid?: string | null, performedUserName?: string | null }> | null } | null } | null };

export type SendEFacturaToAccountingMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  eid?: Types.InputMaybe<Types.Accounts_ExternalIdInput>;
}>;


export type SendEFacturaToAccountingMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', sendEFacturaToAccountingUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type IgnoreEFacturaMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data?: Types.InputMaybe<Types.Accounts_EFacturaInboxIgnoreRepresentationInput>;
}>;


export type IgnoreEFacturaMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', ignoreEFacturaUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type RejectEFacturaMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data?: Types.InputMaybe<Types.Accounts_EFacturaInboxRejectRepresentationInput>;
}>;


export type RejectEFacturaMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', rejectEFacturaUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type GetClientDocumentDataQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter: Types.Scalars['String'];
}>;


export type GetClientDocumentDataQuery = { __typename?: 'Query', documents?: { __typename?: 'documents', GetClientDocumentsUsingGET?: Array<{ __typename?: 'documents_Document', eid?: string | null, clientEid?: string | null, status?: { __typename?: 'documents_FinancialDocumentStatus', statusCode?: string | null } | null }> | null } | null };

export type GetEFacturaOutboxListQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  filter?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetEFacturaOutboxListQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaOutboxListUsingGET?: { __typename?: 'accounts_EFacturaOutboxListRepresentation', recordsCount?: number | null, first?: number | null, last?: number | null, pageSize?: number | null, data?: Array<{ __typename?: 'accounts_EFacturaOutboxRepresentation', externalId?: string | null, invoiceEid?: string | null, clientEid?: string | null, clientName?: string | null, sysEFacturaDocumentType?: string | null, sysEFacturaOutboxStatus?: string | null, amount?: number | null, issueDate?: number | null, dueDate?: number | null, originalFileEid?: string | null, errors?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, invoiceVersion?: number | null, isCorrection?: boolean | null, momentCreated?: string | null, partnerCif?: string | null, partnerName?: string | null, serialNo?: string | null, timeUploaded?: string | null, isIgnored?: boolean | null, hasTechnicalError?: boolean | null, hasNotReadMessages?: boolean | null, hasMessages?: boolean | null, documentEid?: string | null, accountingStatus?: string | null, messageInbox?: Array<{ __typename?: 'accounts_EFacturaInboxNotificationMessageRepresentation', clientEid?: string | null, clientName?: string | null, externalId?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, message?: string | null, momentCreated?: string | null, momentUploaded?: string | null, originalFileEid?: string | null, partnerCif?: string | null, partnerName?: string | null, userEid?: string | null, userName?: string | null, isRead?: boolean | null }> | null }> | null } | null } | null };

export type GetEFacturaOutboxQueryVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  externalId: Types.Scalars['String'];
}>;


export type GetEFacturaOutboxQuery = { __typename?: 'Query', accounts?: { __typename?: 'accounts', getEFacturaOutboxUsingGET?: { __typename?: 'accounts_EFacturaOutboxRepresentation', externalId?: string | null, invoiceEid?: string | null, clientEid?: string | null, clientName?: string | null, sysEFacturaDocumentType?: string | null, sysEFacturaOutboxStatus?: string | null, amount?: number | null, issueDate?: number | null, dueDate?: number | null, originalFileEid?: string | null, errors?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, invoiceVersion?: number | null, isCorrection?: boolean | null, momentCreated?: string | null, partnerCif?: string | null, partnerName?: string | null, serialNo?: string | null, timeUploaded?: string | null, isIgnored?: boolean | null, hasTechnicalError?: boolean | null, hasNotReadMessages?: boolean | null, hasMessages?: boolean | null, documentEid?: string | null, accountingStatus?: string | null, messageInbox?: Array<{ __typename?: 'accounts_EFacturaInboxNotificationMessageRepresentation', clientEid?: string | null, clientName?: string | null, externalId?: string | null, indexDescarcare?: string | null, indexIncarcare?: string | null, message?: string | null, momentCreated?: string | null, momentUploaded?: string | null, originalFileEid?: string | null, partnerCif?: string | null, partnerName?: string | null, userEid?: string | null, userName?: string | null, isRead?: boolean | null }> | null } | null } | null };

export type SubmitEFacturaMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  invoiceEid: Types.Scalars['String'];
  isCorrection: Types.Scalars['Boolean'];
}>;


export type SubmitEFacturaMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', submitEFacturaUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type IgnoreEFacturaOutboxMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  eid?: Types.InputMaybe<Types.Accounts_ExternalIdInput>;
}>;


export type IgnoreEFacturaOutboxMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', ignoreEFacturaOutboxEntryUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type MarkAllNotificationMessageInboxReadMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  eid: Types.Accounts_ExternalIdInput;
}>;


export type MarkAllNotificationMessageInboxReadMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', markAllNotificationMessageInboxReadUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type SendEFacturaOutboxToAccountingMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  eid?: Types.InputMaybe<Types.Accounts_ExternalIdInput>;
}>;


export type SendEFacturaOutboxToAccountingMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', sendEFacturaOutboxToAccountingUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export type IgnoreEFacturaOutboxFromAccountingMutationVariables = Types.Exact<{
  clientEid: Types.Scalars['String'];
  data?: Types.InputMaybe<Types.Accounts_EFacturaOutboxIgnoreRepresentationInput>;
}>;


export type IgnoreEFacturaOutboxFromAccountingMutation = { __typename?: 'Mutation', accounts?: { __typename?: 'accounts_mutation', ignoreEFacturaOutboxFromAccountingUsingPOST?: { __typename?: 'accounts_SuccessResponse', Message?: string | null } | null } | null };

export const GetEFacturaClientConfigDocument = gql`
    query getEFacturaClientConfig($clientEid: String!) {
  accounts {
    getEFacturaClientConfigUsingGET(clientEid: $clientEid) {
      lastRefreshMoment
      automaticInvoiceSubmitAfterNumDays
      isAutomaticInvoiceSubmitEnabled
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaClientConfigGQL extends Apollo.Query<GetEFacturaClientConfigQuery, GetEFacturaClientConfigQueryVariables> {
    document = GetEFacturaClientConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEFacturaClientConfigAutomaticSubmissionDocument = gql`
    mutation updateEFacturaClientConfigAutomaticSubmission($clientEid: String, $request: accounts_EFacturaClientConfigAutomaticSubmissionUpdateRepresentationInput) {
  accounts {
    updateEFacturaClientConfigAutomaticSubmissionUsingPUT(clientEid: $clientEid, request: $request) {
      lastRefreshMoment
      automaticInvoiceSubmitAfterNumDays
      isAutomaticInvoiceSubmitEnabled
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEFacturaClientConfigAutomaticSubmissionGQL extends Apollo.Mutation<UpdateEFacturaClientConfigAutomaticSubmissionMutation, UpdateEFacturaClientConfigAutomaticSubmissionMutationVariables> {
    document = UpdateEFacturaClientConfigAutomaticSubmissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEFacturaListaMesajeDocument = gql`
    mutation updateEFacturaListaMesaje($zile: Int!, $clientEid: String!) {
  accounts {
    updateEFacturaListaMesajeUsingPOST(zile: $zile, clientEid: $clientEid) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEFacturaListaMesajeGQL extends Apollo.Mutation<UpdateEFacturaListaMesajeMutation, UpdateEFacturaListaMesajeMutationVariables> {
    document = UpdateEFacturaListaMesajeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaStatisticsDocument = gql`
    query getEFacturaStatistics($clientEid: String!) {
  accounts {
    getEFacturaStatisticsUsingGET(clientEid: $clientEid) {
      outboxWithErrorCount
      inboxNotVerifiedCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaStatisticsGQL extends Apollo.Query<GetEFacturaStatisticsQuery, GetEFacturaStatisticsQueryVariables> {
    document = GetEFacturaStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaOutboxOpsDocument = gql`
    query getEFacturaOutboxOps($clientEid: String!, $logicalGroup: String) {
  accounts {
    getCompanyParamListUsingGET(clientEid: $clientEid, logicalGroup: $logicalGroup) {
      data {
        logicalGroup
        code
        value
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaOutboxOpsGQL extends Apollo.Query<GetEFacturaOutboxOpsQuery, GetEFacturaOutboxOpsQueryVariables> {
    document = GetEFacturaOutboxOpsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaInboxListDocument = gql`
    query getEFacturaInboxList($clientEid: String!, $filter: String, $order: String, $offset: Int, $count: Int) {
  accounts {
    getEFacturaInboxListUsingGET(clientEid: $clientEid, filter: $filter, order: $order, offset: $offset, count: $count) {
      recordsCount
      first
      last
      pageSize
      data {
        externalId
        clientEid
        clientName
        status
        documentType
        issueDate
        amount
        momentCreated
        serialNo
        partnerCif
        partnerName
        originalFileEid
        readableFileEid
        documentEid
        lastStatusChangeDateTime
        lastStatusChangeUserEid
        lastStatusChangeUserName
        dueDate
        timeUploaded
        indexDescarcare
        indexIncarcare
        isProtected
        messageOutbox {
          clientEid
          clientName
          externalId
          indexDescarcare
          indexIncarcare
          message
          momentCreated
          momentUploaded
          originalFileEid
          partnerCif
          partnerName
          userEid
          userName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaInboxListGQL extends Apollo.Query<GetEFacturaInboxListQuery, GetEFacturaInboxListQueryVariables> {
    document = GetEFacturaInboxListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaInboxDocument = gql`
    query getEFacturaInbox($clientEid: String!, $externalId: String!) {
  accounts {
    getEFacturaInboxUsingGET(clientEid: $clientEid, externalId: $externalId) {
      externalId
      clientEid
      clientName
      status
      documentType
      issueDate
      amount
      momentCreated
      serialNo
      partnerCif
      partnerName
      originalFileEid
      readableFileEid
      documentEid
      lastStatusChangeDateTime
      lastStatusChangeUserEid
      lastStatusChangeUserName
      dueDate
      timeUploaded
      indexDescarcare
      indexIncarcare
      isProtected
      messageOutbox {
        clientEid
        clientName
        externalId
        indexDescarcare
        indexIncarcare
        message
        momentCreated
        momentUploaded
        originalFileEid
        partnerCif
        partnerName
        userEid
        userName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaInboxGQL extends Apollo.Query<GetEFacturaInboxQuery, GetEFacturaInboxQueryVariables> {
    document = GetEFacturaInboxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaInboxHistoryDocument = gql`
    query getEFacturaInboxHistory($clientEid: String!, $externalId: String!) {
  accounts {
    getEFacturaInboxHistoryUsingGET(clientEid: $clientEid, externalId: $externalId) {
      data {
        externalId
        description
        performedDateTime
        performedUserEid
        performedUserName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaInboxHistoryGQL extends Apollo.Query<GetEFacturaInboxHistoryQuery, GetEFacturaInboxHistoryQueryVariables> {
    document = GetEFacturaInboxHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendEFacturaToAccountingDocument = gql`
    mutation sendEFacturaToAccounting($clientEid: String!, $eid: accounts_ExternalIdInput) {
  accounts {
    sendEFacturaToAccountingUsingPOST(clientEid: $clientEid, eid: $eid) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEFacturaToAccountingGQL extends Apollo.Mutation<SendEFacturaToAccountingMutation, SendEFacturaToAccountingMutationVariables> {
    document = SendEFacturaToAccountingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IgnoreEFacturaDocument = gql`
    mutation ignoreEFactura($clientEid: String!, $data: accounts_EFacturaInboxIgnoreRepresentationInput) {
  accounts {
    ignoreEFacturaUsingPOST(clientEid: $clientEid, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IgnoreEFacturaGQL extends Apollo.Mutation<IgnoreEFacturaMutation, IgnoreEFacturaMutationVariables> {
    document = IgnoreEFacturaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RejectEFacturaDocument = gql`
    mutation rejectEFactura($clientEid: String!, $data: accounts_EFacturaInboxRejectRepresentationInput) {
  accounts {
    rejectEFacturaUsingPOST(clientEid: $clientEid, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectEFacturaGQL extends Apollo.Mutation<RejectEFacturaMutation, RejectEFacturaMutationVariables> {
    document = RejectEFacturaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClientDocumentDataDocument = gql`
    query getClientDocumentData($clientEid: String!, $filter: String!) {
  documents {
    GetClientDocumentsUsingGET(clientEid: $clientEid, filter: $filter) {
      eid
      clientEid
      status {
        statusCode
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClientDocumentDataGQL extends Apollo.Query<GetClientDocumentDataQuery, GetClientDocumentDataQueryVariables> {
    document = GetClientDocumentDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaOutboxListDocument = gql`
    query getEFacturaOutboxList($clientEid: String!, $filter: String, $order: String, $offset: Int, $count: Int) {
  accounts {
    getEFacturaOutboxListUsingGET(clientEid: $clientEid, filter: $filter, order: $order, offset: $offset, count: $count) {
      recordsCount
      first
      last
      pageSize
      data {
        externalId
        invoiceEid
        clientEid
        clientName
        sysEFacturaDocumentType
        sysEFacturaOutboxStatus
        amount
        issueDate
        dueDate
        originalFileEid
        errors
        indexDescarcare
        indexIncarcare
        invoiceVersion
        isCorrection
        momentCreated
        partnerCif
        partnerName
        serialNo
        timeUploaded
        isIgnored
        hasTechnicalError
        hasNotReadMessages
        hasMessages
        documentEid
        accountingStatus
        messageInbox {
          clientEid
          clientName
          externalId
          indexDescarcare
          indexIncarcare
          message
          momentCreated
          momentUploaded
          originalFileEid
          partnerCif
          partnerName
          userEid
          userName
          isRead
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaOutboxListGQL extends Apollo.Query<GetEFacturaOutboxListQuery, GetEFacturaOutboxListQueryVariables> {
    document = GetEFacturaOutboxListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEFacturaOutboxDocument = gql`
    query getEFacturaOutbox($clientEid: String!, $externalId: String!) {
  accounts {
    getEFacturaOutboxUsingGET(clientEid: $clientEid, externalId: $externalId) {
      externalId
      invoiceEid
      clientEid
      clientName
      sysEFacturaDocumentType
      sysEFacturaOutboxStatus
      amount
      issueDate
      dueDate
      originalFileEid
      errors
      indexDescarcare
      indexIncarcare
      invoiceVersion
      isCorrection
      momentCreated
      partnerCif
      partnerName
      serialNo
      timeUploaded
      isIgnored
      hasTechnicalError
      hasNotReadMessages
      hasMessages
      documentEid
      accountingStatus
      messageInbox {
        clientEid
        clientName
        externalId
        indexDescarcare
        indexIncarcare
        message
        momentCreated
        momentUploaded
        originalFileEid
        partnerCif
        partnerName
        userEid
        userName
        isRead
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEFacturaOutboxGQL extends Apollo.Query<GetEFacturaOutboxQuery, GetEFacturaOutboxQueryVariables> {
    document = GetEFacturaOutboxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitEFacturaDocument = gql`
    mutation submitEFactura($clientEid: String!, $invoiceEid: String!, $isCorrection: Boolean!) {
  accounts {
    submitEFacturaUsingPOST(clientEid: $clientEid, invoiceEid: $invoiceEid, isCorrection: $isCorrection) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitEFacturaGQL extends Apollo.Mutation<SubmitEFacturaMutation, SubmitEFacturaMutationVariables> {
    document = SubmitEFacturaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IgnoreEFacturaOutboxDocument = gql`
    mutation ignoreEFacturaOutbox($clientEid: String!, $eid: accounts_ExternalIdInput) {
  accounts {
    ignoreEFacturaOutboxEntryUsingPOST(clientEid: $clientEid, eid: $eid) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IgnoreEFacturaOutboxGQL extends Apollo.Mutation<IgnoreEFacturaOutboxMutation, IgnoreEFacturaOutboxMutationVariables> {
    document = IgnoreEFacturaOutboxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkAllNotificationMessageInboxReadDocument = gql`
    mutation markAllNotificationMessageInboxRead($clientEid: String!, $eid: accounts_ExternalIdInput!) {
  accounts {
    markAllNotificationMessageInboxReadUsingPOST(clientEid: $clientEid, eid: $eid) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkAllNotificationMessageInboxReadGQL extends Apollo.Mutation<MarkAllNotificationMessageInboxReadMutation, MarkAllNotificationMessageInboxReadMutationVariables> {
    document = MarkAllNotificationMessageInboxReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendEFacturaOutboxToAccountingDocument = gql`
    mutation sendEFacturaOutboxToAccounting($clientEid: String!, $eid: accounts_ExternalIdInput) {
  accounts {
    sendEFacturaOutboxToAccountingUsingPOST(clientEid: $clientEid, eid: $eid) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEFacturaOutboxToAccountingGQL extends Apollo.Mutation<SendEFacturaOutboxToAccountingMutation, SendEFacturaOutboxToAccountingMutationVariables> {
    document = SendEFacturaOutboxToAccountingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IgnoreEFacturaOutboxFromAccountingDocument = gql`
    mutation ignoreEFacturaOutboxFromAccounting($clientEid: String!, $data: accounts_EFacturaOutboxIgnoreRepresentationInput) {
  accounts {
    ignoreEFacturaOutboxFromAccountingUsingPOST(clientEid: $clientEid, data: $data) {
      Message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IgnoreEFacturaOutboxFromAccountingGQL extends Apollo.Mutation<IgnoreEFacturaOutboxFromAccountingMutation, IgnoreEFacturaOutboxFromAccountingMutationVariables> {
    document = IgnoreEFacturaOutboxFromAccountingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }